import React, { Suspense } from "react"
import ReactDOM from "react-dom"
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'
import "./index.css"
import "./styles.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import "./i18n"

const matomoInstance = createInstance({
	urlBase: 'https://analytics.mywikis.com',
	siteId: 6,
	userId: undefined, // optional, default value: `undefined`.
	trackerUrl: 'https://analytics.mywikis.com/matomo.php', // optional, default value: `${urlBase}matomo.php`
	srcUrl: 'https://analytics.mywikis.com/matomo.js', // optional, default value: `${urlBase}matomo.js`
	disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
	heartBeat: { // optional, enabled by default
	  active: true, // optional, default value: true
	  seconds: 10 // optional, default value: `15
	},
	linkTracking: false, // optional, default value: true
	configurations: { // optional, default value: {}
	  // any valid matomo configuration, all below are optional
	  disableCookies: true,
	  setSecureCookie: true,
	  setRequestMethod: 'POST'
	}
  })


ReactDOM.render(
	<React.StrictMode>
		<Suspense fallback={<div></div>}>
			<MatomoProvider value={matomoInstance}>
				<App />
			</MatomoProvider>
		</Suspense>
	</React.StrictMode>,
	document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
